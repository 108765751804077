import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, vShow as _vShow, withCtx as _withCtx, createVNode as _createVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-697e17fe"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = {
  class: "line-clamp",
  style: {"margin-bottom":"10px"}
}
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_thumbnail = _resolveComponent("ion-thumbnail")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_item = _resolveComponent("ion-item")!

  return (_openBlock(), _createBlock(_component_ion_item, {
    class: "info-item",
    lines: "full",
    onClick: _cache[3] || (_cache[3] = ($event: any) => ($setup.openBrowser($props.info.newsLink, $props.info.id))),
    button: ""
  }, {
    default: _withCtx(() => [
      _withDirectives(_createVNode(_component_ion_thumbnail, { slot: "start" }, {
        default: _withCtx(() => [
          _createElementVNode("img", {
            src: $props.info.imageLink && $props.info.imageLink != 'False' ? $props.info.imageLink : require('@/assets/no_image.jpeg'),
            referrerpolicy: "no-referrer",
            crossorigin: "anonymous"
          }, null, 8, _hoisted_1)
        ]),
        _: 1
      }, 512), [
        [_vShow, $props.info.imageLink]
      ]),
      _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
        default: _withCtx(() => [
          _createElementVNode("h2", _hoisted_2, _toDisplayString($props.info.title), 1),
          _createElementVNode("p", null, [
            _createElementVNode("img", {
              style: {"vertical-align":"middle"},
              src: $setup.getInfoSrcImg($props.infoSources, $props.info.source),
              referrerpolicy: "no-referrer"
            }, null, 8, _hoisted_3),
            _createTextVNode("  "),
            _createElementVNode("small", null, _toDisplayString($props.info.source.split(" ")[0]) + " · " + _toDisplayString($setup.getRelativeDate($props.info.datetime)), 1)
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_ion_buttons, { style: {"position":"absolute","bottom":"0","right":"0"} }, {
        default: _withCtx(() => [
          ($props.userLoggedIn && $props.info.likedByUser)
            ? (_openBlock(), _createBlock(_component_ion_button, {
                key: 0,
                onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => ($setup.updateUserLikedInfo($props.info, 'remove')), ["stop"]))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, {
                    slot: "icon-only",
                    icon: $setup.heart,
                    color: "danger"
                  }, null, 8, ["icon"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          ($props.userLoggedIn && !$props.info.likedByUser)
            ? (_openBlock(), _createBlock(_component_ion_button, {
                key: 1,
                onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => ($setup.updateUserLikedInfo($props.info, 'add')), ["stop"]))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, {
                    slot: "icon-only",
                    icon: $setup.heartOutline,
                    color: "danger"
                  }, null, 8, ["icon"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_ion_button, {
            onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => ($setup.shareInfo($props.info)), ["stop"]))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_icon, {
                slot: "icon-only",
                icon: $setup.shareSocialOutline
              }, null, 8, ["icon"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}