
// icons
import { notifications, pin, star, heart, heartOutline, shareSocialOutline, } from 'ionicons/icons';

// components
import { IonItem, IonLabel, IonChip, IonBadge, IonIcon, IonThumbnail, IonAvatar, IonImg,
        IonButtons, IonButton, loadingController, } from '@ionic/vue';

// composables
import { utils } from '@/composables/utils';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

export default {
  props: [
    "info",
    "infoSources",
    "userLoggedIn",
  ],
  components: { IonItem, IonLabel, IonChip, IonBadge, IonIcon, IonThumbnail, IonAvatar, IonImg,
                IonButtons, IonButton, },
  setup() {
    const store = useStore();
    const { t } = useI18n();
    const { formatDate, openBrowser, sleep, openSocialShare, getRelativeDate } = utils();

    const updateUserLikedInfo = async (info: any, action = 'add') => {
      const loading = await loadingController.create({});
      await loading.present();
      store.dispatch('updateUserLikedInfo', { info, id: info.id, action });
      await sleep(1); // 1 second
      loading.dismiss();
    }

    const shareInfo = async (info: any) => {
      const { title, newsLink } = info;
      await openSocialShare(`${title} | ${newsLink}`, newsLink);
    };

    const getInfoSrcImg = (infoSources: any, src: any) => {
      const srcObj = infoSources.find((row: any) => row.source == src);
      return srcObj ? srcObj.logo : "";
    }

    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      notifications, pin, star, heart, heartOutline, shareSocialOutline,

      // methods
      t, formatDate, getRelativeDate, openBrowser,
      updateUserLikedInfo, shareInfo, getInfoSrcImg
    }
  }
}
